<template>
  <div
    ref="item"
    class="print-item"
    :style="{paddingTop: settings.offset + '%'}"
  >
    <div class="print-item-head print-item-title">
      <img
        v-if="settings.image.show && item.photo"
        :style="{width: settings.image.size + 'em', height: settings.image.size + 'em', borderRadius: settings.image.radius + '%'}"
        :src="`${VUE_APP_IMG_BASE}/tr:w-0.2/${item.photo}`"
        alt=""
      >
      <div class="print-item-header">
        <span class="print-name">
          {{ item.name }}
          <span
            v-if="!item.options.choose"
            aria-hidden="true"
          /> <!-- leading price dots -->
        </span>
        <span
          class="print-price"
          :style="item.options.choose && 'visibility: hidden'"
        >{{ displayPrice(item) }}</span>
        <span
          v-if="item.description"
          class="print-item-description"
          :style="{ fontSize: settings.description.size * 100 / settings.title.size + '%'}"
        >
          {{ item.description }}
        </span>
      </div>
    </div>
    <item-options
      v-if="item.options.choose"
      :option="item.options.choose"
      :offset-top="settings.optionOffset"
      :column-gap="settings.gap"
      :currency="currency"
      :columns="settings.columns || 1"
      class="print-option-description"
    />
    <hr
      v-if="item.options.choose && item.options.add"
      style="margin:2% 0; border: none"
    >
    <item-options
      v-if="item.options.add"
      :option="item.options.add"
      :offset-top="settings.optionOffset"
      :column-gap="settings.gap"
      :is-option-add="true"
      :currency="currency"
      :columns="settings.columns || 1"
      class="print-option-description"
    />
  </div>
</template>

<script>
import ItemOptions from '@/components/paper-menu-designer/ItemOptions.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PmdOutputItem',
  components: { ItemOptions },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    settings: {
      type: Object,
      required: true,
      default: () => {},
    },
    currency: {
      type: Object,
      required: false,
      default: () => {},
    },
    rerender: { type: Boolean, required: true, default: false },
  },
  computed: {
    ...mapGetters({
      VUE_APP_IMG_BASE: 'main/VUE_APP_IMG_BASE',
    }),
  },
  watch: {
    rerender(v) {
      if (v) this.$emit('update', this.$refs.item)
    },
  },
  mounted() {
    this.$emit('update', this.$refs.item)
  },
  methods: {
    displayPrice(item) {
      const p = item.price || 0
      let price = `${this.currency.inFront ? `${this.currency.symbol}\u2009${p}` : `${p}\u2009${this.currency.symbol}`}`
      if (item.amount) price = `${price} / ${item.amount} ${item.measurementUnit}`
      return price
    },
  },
}
</script>

<style lang="sass">
.print-item-head
  display: flex
  align-items: flex-start
  img
    margin-right: 1%
.print-item-header, .print-option-header
  width: 100%
  display: grid
  grid-template-columns: auto max-content
  align-items: end
span.print-name
  position: relative
  overflow: hidden
  > span:after
    position: absolute
    padding-inline-start: 1ch
    letter-spacing: var(--price-leader-letter-spacing)
    content: var(--price-leader-symbol)
    text-align: right
    opacity: 50%
span.print-price
  font-variant-numeric: tabular-nums
  text-align: right
  margin-inline-start: 1ch
.print-item-description
  text-wrap: wrap
</style>
